@import "~antd/dist/antd.css";
.ant-layout-header {
  background-color: white;
}

.header-styling {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-avatar-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Utility Classes */

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-top-x {
  margin-top: 20px;
}

.margin-top-2x {
  margin-top: 40px;
}

.margin-bottom-x {
  margin-bottom: 20px;
}

.margin-bottom-2x {
  margin-bottom: 40px;
}

.margin-right-x {
  margin-right: 8px !important;
}

.ant-layout-content {
  padding: 16px !important;
}